import React from 'react';
// import logo from './logo.svg';
// import Header from './components/Header';
// import Footer from './components/Footer';
// import Routes from './constants/Routes';
import Calc from './components/Calc';
// import Game from './components/Game';
// import IRoute from './interfaces/IRoute';
// import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './css/usicalc.scss';

const App = () => {
  // const RenderRoute = (route: IRoute) => (
  //   route.exact
  //     ? <Route path={route.url} exact component={route.comp} />
  //     : <Route path={route.url} component={route.comp} />
  // );

  return (
    <div id="app">
      {/* <Game /> */}
      <Calc />
<a className="button" href="https://covid.usidistrib.com">UsiCovid</a>
      {/* <Router>
        <Header />
        <div id="content">
        <Switch>
          {Routes.map((route) => RenderRoute(route))}
        </Switch>
        </div>
        <Footer />
      </Router> */}
    </div>
  );
}

export default App;
