import React, {useState, useEffect} from 'react';
import '../css/calcbutton.scss';

interface Props {
  label: string;
  onClick: () => void
}

// , set
// onClick={set(label)}
const CalcButton = ({label, onClick} : Props) => {
  const [lab, setLabel] = useState(label);

  useEffect(() => {
    setLabel(label);
  });

  return (
    <button 
    className="calc-item" 
    onClick={() => onClick() } >
      {lab}
    </button>
  )
};

export default CalcButton;
