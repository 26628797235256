import React, {useState, useReducer, useEffect} from 'react';
import CalcButton from './CalcButton';
import '../css/calc.scss';


const Calc = () => {
    const [result, setResult] = useState("0");

  const HandleClick = (value: string) => {
    {setResult(value)}
  }

  
  return (
<div className="calc">
    <p className="calc-result">{result}</p>
    <ul>
    {/* <li><CalcButton label="7" onClick={() => HandleClick("7")} /></li> */}
    <li><CalcButton label="7" onClick={() => HandleClick("7")} /></li>
    <li><CalcButton label="8" onClick={() => HandleClick("8")} /></li>
    <li><CalcButton label="9" onClick={() => HandleClick("9")} /></li>
    <li><CalcButton label="4" onClick={() => HandleClick("4")} /></li>
    <li><CalcButton label="5" onClick={() => HandleClick("5")} /></li>
    <li><CalcButton label="6" onClick={() => HandleClick("6")} /></li>
    <li><CalcButton label="1" onClick={() => HandleClick("1")} /></li>
    <li><CalcButton label="2" onClick={() => HandleClick("2")} /></li>
    <li><CalcButton label="3" onClick={() => HandleClick("3")} /></li>
    <li><CalcButton label="<-" onClick={() => HandleClick("0")} /></li>
    <li><CalcButton label="0" onClick={() => HandleClick("0")} /></li>
    <li><CalcButton label="C" onClick={() => HandleClick("0")} /></li>
    </ul>
  </div>
)
  };

export default Calc;
